<template>
  <b-card>
    <h4 class="mb-75 text-primary">Welcome, {{ customerDetails.firstName }}</h4>
    <p class="mb-1">You are currently viewing account details for:</p>
    <p class="mb-0 text-secondary">
      <strong>{{ $store.state.app.selectedPremiseName }}</strong>
    </p>
    <p>
      {{ $store.state.app.selectedPremiseAddress }}
    </p>
    <p class="mt-2">
      <strong>Account ID:</strong>
      {{ $store.state.app.selectedPremiseHumanID }}
    </p>
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue";

export default {
  props: ["username"],
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
      customerDetails: JSON.parse(localStorage.getItem("userData")),
    };
  },
};
</script>
