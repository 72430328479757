<template>
  <b-card
    v-if="blogList"
    :img-src="blogList[0].img"
    img-alt="Card image"
    img-top
  >
    <b-link :to="{ name: 'news-waste-detail', params: { id: blogList[0].id } }">
      <b-card-text class="h-100">
        <strong>{{ blogList[0].title }}</strong>
      </b-card-text>
    </b-link>
  </b-card>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import {
  BCard,
  BImg,
  BLink,
  BCol,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BImg,
    BCol,
    BLink,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  data() {
    return {
      blogList: null,
    };
  },
  methods: {
    getBlogPosts: function () {
      this.$http.get("/blog/list/data").then((res) => {
        this.blogList = res.data;
      });
    },
  },
  created: function () {
    this.getBlogPosts();
  },
};
</script>

<style>
[dir] .card-body {
  padding: 1rem;
}
</style>
