<template>
    <b-card no-body>
        <b-card-header class="pb-0">
            <b-card-title>Order Tracker</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0 text-primary">
                240L Bin Food
            </b-card-text>
        </b-card-header>
        <!--/ title and dropdown -->

        <b-card-body>
            <b-row>
                <b-tabs class="col-12 mt-1">
                    <b-tab active>
                        <template #title>
                            <feather-icon icon="RotateCwIcon" />
                            <span>Progress</span>
                        </template>
                        <b-col class="d-flex justify-content-center">
                            <!-- apex chart -->
                            <apexchart
                                width="250"
                                id="order-progress"
                                type="radialBar"
                                :options="chartOptions"
                                :series="series"
                            ></apexchart>
                        </b-col>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <feather-icon icon="ClockIcon" />
                            <span>Timeline</span>
                        </template>
                        <app-timeline class="mt-2">
                            <app-timeline-item
                                title="Order Placed"
                                time="10 days ago"
                                variant="primary"
                            />
                            <app-timeline-item
                                title="Order Processed"
                                time="9 days ago"
                                variant="primary"
                            />
                            <app-timeline-item
                                title="Delivery Date Confirmed"
                                time="3 days ago"
                                variant="primary"
                            />
                            <app-timeline-item
                                title="Order Received"
                                time=""
                                variant="secondary"
                            />
                            <app-timeline-item
                                title="First Collection"
                                time=""
                                variant="secondary"
                            />
                        </app-timeline>
                    </b-tab>
                </b-tabs>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BTabs,
    BTab
} from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardHeader,
        BCardTitle,
        BDropdown,
        BDropdownItem,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BTabs,
        BTab,
        AppTimeline,
        AppTimelineItem
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data: function() {
        return {
            series: [60],
            chartOptions: {
                chart: {
                    height: 300,
                    type: "radialBar",
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: 0,
                        endAngle: 360,
                        hollow: {
                            margin: 0,
                            size: "80%",
                            background: "#fff",
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: "front",
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: "#fff",
                            strokeWidth: "100%",
                            margin: 0, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },

                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                show: true,
                                color: "#888",
                                fontSize: "17px"
                            },
                            value: {
                                formatter: function(val) {
                                    return parseInt(val) + "%";
                                },
                                color: "#111",
                                fontSize: "36px",
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#00a19a"],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Progress"]
            }
        };
    }
};
</script>
