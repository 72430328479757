<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col sm="12" lg="6" xl="3">
        <business-details />
      </b-col>
      <b-col sm="12" lg="6" xl="3">
        <next-collections />
      </b-col>
      <b-col sm="12" lg="6" xl="3">
        <latest-news />
      </b-col>
      <b-col sm="12" lg="6" xl="3">
        <contact-b-w />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <calendar />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardText, BLink, BCol, BRow } from "bootstrap-vue";
import Calendar from "../calendar/Calendar.vue";
import BusinessDetails from "./BusinessDetails.vue";
import NextCollections from "./NextCollections.vue";
import OrderTracker from "./OrderTracker.vue";
import LatestNews from "./LatestNews.vue";
import ContactBW from "./ContactBW.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BCol,
    BRow,

    Calendar,
    BusinessDetails,
    NextCollections,
    OrderTracker,
    LatestNews,
    ContactBW,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
