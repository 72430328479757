<template>
  <b-card>
    <!-- <div class="card-header p-0">
      <h4 class="card-title mb-75">Contact Us</h4>
    </div> -->
    <b-row>
      <b-col sm="12">
        <b-button
          block
          variant="outline-primary"
          class="my-1"
          :to="{ name: 'contact' }"
        >
          <feather-icon icon="MailIcon" /> Contact Us
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <b-button
          block
          variant="outline-primary"
          class="my-1"
          :href="
            'https://www.betterwaste.co.uk/ical/ical.php?caccid=' + machineID
          "
        >
          <feather-icon icon="CalendarIcon" /> Add to Calendar/Phone
        </b-button>
      </b-col>
    </b-row>

    <b-row v-if="premiseNewNotifications.length > 0">
      <b-col sm="12">
        <b-button
          block
          variant="outline-danger"
          class="my-1"
          v-b-modal.modal-notifications
        >
          <feather-icon icon="BellIcon" />
          {{ premiseNewNotifications.length }} new Notifications
        </b-button>

        <!-- basic modal -->
        <b-modal id="modal-notifications" centered>
          <b-card-text>
            <ul class="p-0 m-0 list-group">
              <li
                v-for="notifications in premiseNewNotifications"
                :key="notifications.NoteId"
                class="list-group-item"
              >
                <h5>
                  {{ getHumanDate(notifications.NoteDate) }}
                </h5>
                <p>{{ notifications.NoteTxt }}</p>
              </li>
            </ul>
          </b-card-text>
          <template #modal-footer>
            <div class="text-center w-100">
              <b-button
                size="sm"
                variant="outline-danger"
                :to="{ name: 'notifications' }"
              >
                View all Notifications
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col sm="12">
        <b-button
          block
          variant="outline-primary"
          class="my-1"
          :to="{ name: 'notifications' }"
        >
          <feather-icon icon="MessageSquareIcon" /> View Notifications
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BLink, BButton, BCol, BRow, BCard, BCardText } from "bootstrap-vue";
import store from "@/store/index";
import moment from "moment";

export default {
  components: {
    BCard,
    BButton,
    BCol,
    BRow,
    BLink,
    BCardText,
  },
  data() {
    return {};
  },
  created: function () {
    this.getNotifications();
  },
  watch: {
    // whenever account changes, this function will run
    machineID() {
      this.getNotifications();
    },
  },
  computed: {
    premiseCollections: function () {
      return store.state.app.selectedPremiseNextCollections;
    },
    premiseName: function () {
      return store.state.app.selectedPremiseName;
    },
    machineID: function () {
      return store.state.app.selectedPremiseID;
    },
    premiseNewNotifications: function () {
      return store.state.app.selectedPremiseNewNotifications;
    },
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getNotifications: function () {
      fetch(
        "https://prod-10.uksouth.logic.azure.com:443/workflows/c4de71f7a0644d8fad586bd65a2a247e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=AgJC0d7BOGzmvrT0l2W6N1Jt_pldi8Za3M00Y-A8lCU",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            MachineId: this.machineID,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP status " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          if (!data.ErrCode) {
            store.commit(
              "app/UPDATE_SELECTED_PREMISE_NOTIFICATIONS",
              data.MessageList
            );
            localStorage.setItem(
              "selectedPremiseNotifications",
              JSON.stringify(data.MessageList)
            );

            const newNotifications = [];
            // Loop all Message and check against Last Login to see if New Notifications exist
            data.MessageList.forEach((notification) => {
              if (
                moment(store.state.app.selectedPremiseLastLogin).isBefore(
                  notification.NoteDate
                )
              ) {
                newNotifications.push(notification);
              }
            });

            store.commit(
              "app/UPDATE_SELECTED_PREMISE_NEW_NOTIFICATIONS",
              newNotifications
            );
            localStorage.setItem(
              "selectedPremiseNewNotifications",
              JSON.stringify(newNotifications)
            );
          } else {
            store.commit("app/UPDATE_SELECTED_PREMISE_NOTIFICATIONS", []);
            localStorage.removeItem("selectedPremiseNotifications");
          }
        });
    },
  },
};
</script>
