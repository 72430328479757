<template>
  <b-card class="card-developer-meetup">
    <div class="card-header p-0">
      <h4 class="card-title mb-75">Next Collections</h4>
    </div>
    <!-- collection -->
    <b-media no-body v-for="collection in nextCollections" :key="collection.id">
      <b-media-aside class="mr-1" @if="collection.date">
        <b-avatar
          rounded
          size="34"
          :style="{ backgroundColor: colors[collection.id].backgroundColor }"
        >
          <feather-icon icon="Trash2Icon" size="18" />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <p v-if="collection.inProgress" class="mb-1">
          <span class="bg-dark text-white">&nbsp;Order in Progress&nbsp;</span>
        </p>
        <h6 class="mb-0">{{ collection.name }}</h6>
        <p v-if="collection.binQty" class="mb-0">
          <small>Qty: {{ collection.binQty }}</small>
        </p>
        <p v-if="collection.date" class="mb-0">
          <small>{{ collection.date }}</small>
        </p>
        <p v-if="collection.frequency" class="mb-0 text-primary">
          <small>{{ collection.frequency }}</small>
          <small v-if="collection.weightLimit > 0" class="text-secondary">
            - ({{ collection.weightLimit }}kg limit)</small
          >
        </p>
        <p class="mt-1" v-if="collection.inProgress">
          <b-button
            variant="outline-primary"
            @click="toggleDiv('timeline-' + collection.id)"
            >Show Order Status</b-button
          >
          <app-timeline
            class="mt-2"
            style="display: none"
            :id="'timeline-' + collection.id"
          >
            <app-timeline-item
              title="Order Placed"
              :time="collection.createdOnDate"
              :variant="collection.createdOnDate ? 'primary' : 'secondary'"
            />
            <app-timeline-item
              title="Advised Delivery Date"
              :time="collection.advisedDelDate"
              :variant="collection.advisedDelDate ? 'primary' : 'secondary'"
            />
            <app-timeline-item
              title="Confirmed Delivery Date"
              :time="collection.confirmedDelDate"
              :variant="collection.confirmedDelDate ? 'primary' : 'secondary'"
            />
            <app-timeline-item
              title="First Collection Date"
              :time="collection.firstCollectionDate"
              :variant="
                collection.firstCollectionDate ? 'primary' : 'secondary'
              "
            />
          </app-timeline>
        </p>
      </b-media-body>
    </b-media>
  </b-card>
</template>

<script>
import store from "@/store/index";

import getCollections from "../authentication/getCollections";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BButton,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
  BIcon,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BButton,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BIcon,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      colors: [
        {
          backgroundColor: "#00a19a",
          textColor: "#FFF",
        },
        {
          backgroundColor: "#5D8BF4",
          textColor: "#FFF",
        },
        {
          backgroundColor: "#ED8240",
          textColor: "#FFF",
        },
        {
          backgroundColor: "#283046",
          textColor: "#FFF",
        },
        {
          backgroundColor: "#666",
          textColor: "#FFF",
        },
      ],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    nextCollections: function () {
      return store.state.app.selectedPremiseNextCollections;
    },
  },
  methods: {
    theColors: function (count) {
      let colors = getCollections.getColor(count);
      return colors;
    },
    toggleDiv: function (divID) {
      var div = document.getElementById(divID);
      if (div.style.display === "none") {
        div.style.display = "block";
      } else {
        div.style.display = "none";
      }
    },
  },
};
</script>
